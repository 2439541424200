import React, { useContext } from 'react'
import NetworkContext from '../../context/network-context'
import { Button } from 'react-bootstrap';
import cryptoWallet from '../../images/crypto-wallet-bitcoin-icon.svg'
import "./connect.scss"

import {
  addChain,
  currentProviderIsMetamask,
  networks,
  useTargetNetwork,
} from '../../web3/web3'

const WrongNetwork = () => {
  // TODO: 
  const { network: TARGET_NETWORK } = useTargetNetwork()
  const isMetamask = currentProviderIsMetamask()
  if (!networks[TARGET_NETWORK]) {
    return <Button className="rounded-pill">Invalid Network</Button>
  }

  return isMetamask ? (
    <Button onClick={() => addChain(TARGET_NETWORK)} className="rounded-pill">
      Switch to {networks[TARGET_NETWORK].shortName} network
    </Button>
  ) : (
    <Button disabled variant="outline-warning" className="rounded-pill">
      Please change to {networks[TARGET_NETWORK].shortName} network
    </Button>
  )
}

const Connect = ({ connect }) => {
  return (
    <span
      role="button"
      tabIndex={0}
      onKeyUp={connect}
      className="m-auto"
      onClick={connect}
    >
      <img
        src={cryptoWallet}
        alt="Paste from wallet"
        className="address-buttons"
      />
    </span>
  )
}

const Account = (account, disconnect, networkName) => (
  <>
    <span
      role="button"
      tabIndex={0}
      onKeyPress={disconnect}
      onClick={disconnect}
      className="address-buttons"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        fill="currentColor"
        viewBox="0 0 16 16"
      >
        <path
          fillRule="evenodd"
          d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
        />
        <path
          fillRule="evenodd"
          d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 +2.146a.5.5 0 0 0 .708.708l3-3z"
        />
      </svg>
    </span>
  </>
)

function ConnectWalletIcon() {
  const {
    isCorrectNetwork,
    account,
    connected,
    unlocked,
    network,
    connect,
    disconnect,
  } = useContext(NetworkContext)
  let child

  if (!unlocked || !connected || !account) {
    child = Connect({ connect })
  } else if (unlocked && (!isCorrectNetwork || !networks[network])) {
    child = WrongNetwork()
  } else {
    child = Account(account, disconnect, networks[network].shortName)
  }

  return <div className="d-flex align-items-center">{child}</div>
}

export default ConnectWalletIcon

import React from 'react'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import * as classNames from 'classnames'

function TransactionReview({
  onBack,
  redeemHandler,
  isLoading,
}) {

  return (
    <Container className="d-flex flex-column">
      <div className="d-fex mt-4 mb-4">
        <h1 className={classNames('text-primary mb-4')}>
          Voucher is voucherStatus.toLowerCase!
        </h1>
        <div className="text-left mb-2">
          <small>Fiat Value </small>
          <h5>
            <code className="text-muted">
              voucherAmount symbol.slice.toUpperCase
            </code>
          </h5>
        </div>
        <div className="text-left mb-2">
          <small>Exchange Rate </small>
          <h5>
            <code className="text-muted">
              rate symbol.slice.toUpperCase/symbol.slice.toUpperCase
            </code>
          </h5>
        </div>
        <div className="text-left mb-2">
          <small>Cryptocurrency Value</small>
          <h5>
            <code className="text-muted">
              ~amount symbol.slice.toUpperCase
            </code>{' '}
          </h5>
        </div>
        <div className="text-left mb-2">
          <small>Destination Addreess</small>
          <h5>
            <code className="text-muted">transactionDetails.address</code>{' '}
          </h5>
        </div>
      </div>

      <Row className="flex-wrap-reverse">
        <Col xs={5}>
          <Button
            className="rounded"
            size="tall"
            block
            variant="outline-primary"
            onClick={onBack}
          >
            <svg
              className="bi bi-arrow-left"
              width="1.5em"
              height="1.5em"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M5.854 4.646a.5.5 0 010 .708L3.207 8l2.647 2.646a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708 0z"
                clipRule="evenodd"
              />
              <path
                fillRule="evenodd"
                d="M2.5 8a.5.5 0 01.5-.5h10.5a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
                clipRule="evenodd"
              />
            </svg>
          </Button>
        </Col>
        <Col xs={7}>
          <Button
            className="rounded"
            size="tall"
            // disabled={!isValid}
            block
            variant="primary"
            onClick={redeemHandler}
          >
            {isLoading ? (
              <Spinner animation="border" role="status">
                <span className="sr-only">Loading...</span>
              </Spinner>
            ) : (
              'Redeem'
            )}
          </Button>
        </Col>
      </Row>
    </Container>
  )
}

export default TransactionReview

import * as React from 'react'
import App from '../app/App'
import Content from '../components/content/Content'
import Layout from '../components/layout'
import Seo from '../components/seo'

const IndexPage = () => {
  return (
    <Layout pageInfo={{ pageName: 'index' }}>
      <Seo title="Pay "/>
      <App id="app" />
      <Content id="content"/>
    </Layout>
  )
}

export default IndexPage

export const Head = () => <title>Home Page</title>

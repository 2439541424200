import React, { PureComponent } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import './content.scss'
import temp from '../../images/temoImg.svg'

export default class Content extends PureComponent {
  render() {
    return (
      <section>
        <Container className="benefits">
          <Row className="title mb-5 mx-auto">
            <h2>Key benefits of using task management software</h2>
          </Row>
          <Row className="d-flex justify-content-center text-center">
            <Col md={3}>
              <img src={temp} alt="temp" />

              <h5>Keep tasks in one place</h5>
              <p>
                Save time, avoid losing work and information, delegate, and
                track tasks to stay on schedule
              </p>
            </Col>

            <Col md={3}>
              <img src={temp} alt="temp" />
              <h5>Keep tasks in one place</h5>
              <p>
                Save time, avoid losing work and information, delegate, and
                track tasks to stay on schedule
              </p>
            </Col>

            <Col md={3}>
              <img src={temp} alt="temp" />

              <h5>Keep tasks in one place</h5>
              <p>
                Save time, avoid losing work and information, delegate, and
                track tasks to stay on schedule
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    )
  }
}


import { useCallback, useEffect, useState } from "react";
import { getProvider } from '../../web3/web3.js';
import ERC20Abi from "./abi/ERC20.json";
import { ContractFactoryHof } from "./contract.factory";

export const ContractFactory = ContractFactoryHof(window.web3);

export const Tokens = {
    BUSD: {
        address: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
        decimals: 18,
        chainId: 56,
        core: true,
        symbol: 'BUSD',
        image: "/busd.svg"
    },
    BNB: {
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        decimals: 18,
        chainId: 56,
        core: true,
        symbol: 'BNB',
        image: "/bnb.svg"
    },
};


const CONTRACTS = {
    _BUSDContract_instance: null,
    _BNBContract_instance: null,
    _BnbBusdContract_instance: null,
    _HegeToken_instance: null

};

export const getContract = async (contract, address, abi, networkId = 56) => {
    const contractKey = `${contract}:${networkId}`;
    if (CONTRACTS[contractKey]) {
        return CONTRACTS[contractKey];
    }

    const provider = getProvider(networkId)
    CONTRACTS[contractKey] = await ContractFactoryHof(provider).create(
        abi,
        address
    );
    return CONTRACTS[contractKey];
};

export const useContractWithProvider = (address, abi, provider) => {
    const [contract, setContract] = useState();
    const getContract = useCallback(async () => {
        try {
            if (!address || !provider) {
                return;
            }

            const factory = ContractFactoryHof(provider);
            const _contract = await factory.create(abi, address);
            if (!_contract) {
                return;
            }
            setContract(_contract);
        } catch (e) {
            console.log(e);
        }
    }, [abi, address, provider, setContract]);

    useEffect(() => getContract(), [getContract]);

    return contract;
};

export const useContractWithProviderAndFunctions = (address, abi, provider) => {
    const _contract = useContractWithProvider(address, abi, provider);
    const [contract, setContract] = useState(null);

    useEffect(() => {
        if (_contract) {
            _contract.write = async ({ method, account, value, params }) => {
                const gasPrice = await provider.eth.getGasPrice();
                const args = {
                    from: account,
                    gasPrice: gasPrice,
                    value
                };
                await _contract.methods[method](...params).estimateGas(args);
                const result = _contract.methods[method](...params).send(args);
                return result;
            }

            _contract.read = async ({ method, params }) => {
                try {
                    // console.log("Reading:::", method, ...params)
                    const result = _contract.methods[method](...params).call();
                    return result;
                } catch (e) {
                    console.log(e)
                }
            }
        }

        setContract(_contract);

    }, [_contract, setContract, provider]);

    return contract;
};

export const useContract = (address, abi, networkId = 56) => {
    const [contract, setContract] = useState(null);

    const contractFn = useCallback(async () => {
        setContract(await getContract(address, address, abi, networkId));
    }, [address, abi, networkId]);

    useEffect(() => contractFn(), [contractFn]);
    return contract;
};

export const useTokenContractWithProvider = (address, provider = window.web3) => {
    const contract = useContractWithProviderAndFunctions(address, ERC20Abi.abi, provider);
    const [supply, setSupply] = useState(0);
    const [decimals, setDecimals] = useState(0);
    const [symbol, setSymbol] = useState();

    const balanceOf = useCallback(async (account) => {
        if (!contract || !account) {
            return 0;
        }
        let balance = await contract.methods.balanceOf(account).call();

        let decimals = await contract.methods.decimals().call();
        return +(balance / 10 ** decimals).toFixed(decimals);
    }, [contract]);

    useEffect(() => {
        if (!contract || !address) {
            return;
        }
        const init = async () => {
            try {
                let decimals = await contract.methods.decimals().call();
                let supply = await contract.methods.totalSupply().call();
                let _symbol = await contract.methods.symbol().call();

                supply = +(supply / 10 ** decimals).toFixed();
                setSupply(supply);
                setDecimals(decimals);

                console.log(symbol);
                setSymbol(_symbol)
            } catch (error) {
                console.log("Could not init contract, check provider, contract::", address, provider && parseInt(provider._provider.chainId));
                console.error(error);
            }

        };
        init();
    }, [contract, address, provider, symbol]);

    return { contract, balanceOf, supply, decimals, symbol };
}

export const useTokenContract = (address, chainId = 56) => {
    const provider = getProvider(chainId);
    return useTokenContractWithProvider(address, provider);
};

import React from 'react'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import './style.module.scss'


const TransactionComplete = ({ transactionDetails, onBack }) => {
    const { amount, symbol, txid, voucherAmount, address } = transactionDetails;

    return (
        <Container className="d-flex flex-column">
            <div className="d-fex mt-4 mb-4">
                <h1 className='text-primary mb-4'>Success!</h1>
                <div className="text-left mb-2">
                    <small >Redeemed</small>
                    <h5><code className="text-muted">{amount} {symbol.slice(0, 3).toUpperCase()}</code></h5>
                </div>
                <div className="text-left mb-2">
                    <small >Cost</small>
                    <h5><code className="text-muted">{voucherAmount} {symbol.slice(3).toUpperCase()}</code></h5>
                </div>
                <div className="text-left mb-2">
                    <small >Transfered issued to</small>
                    <h5>
                        <a href={"https://live.blockcypher.com/btc/tx/" + txid} 
                        target="_blank" rel="noopener noreferrer">
                            <code className="text-muted">{address}</code>
                            <span>
                                <svg className="bi bi-link-45deg" width="1em" height="1em" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M4.715 6.542L3.343 7.914a3 3 0 104.243 4.243l1.828-1.829A3 3 0 008.586 5.5L8 6.086a1.001 1.001 0 00-.154.199 2 2 0 01.861 3.337L6.88 11.45a2 2 0 11-2.83-2.83l.793-.792a4.018 4.018 0 01-.128-1.287z" />
                                    <path d="M5.712 6.96l.167-.167a1.99 1.99 0 01.896-.518 1.99 1.99 0 01.518-.896l.167-.167A3.004 3.004 0 006 5.499c-.22.46-.316.963-.288 1.46z" />
                                    <path d="M6.586 4.672A3 3 0 007.414 9.5l.775-.776a2 2 0 01-.896-3.346L9.12 3.55a2 2 0 012.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 00-4.243-4.243L6.586 4.672z" />
                                    <path d="M10 9.5a2.99 2.99 0 00.288-1.46l-.167.167a1.99 1.99 0 01-.896.518 1.99 1.99 0 01-.518.896l-.167.167A3.004 3.004 0 0010 9.501z" />
                                </svg>
                            </span>
                        </a>
                    </h5>
                </div>
            </div>
            <Button className="rounded" size="tall" block variant="primary" onClick={onBack} >
                Back
            </Button>
        </Container>
    )
}

export default TransactionComplete

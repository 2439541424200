import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Modal,
  Container,
  Row,
  Col,
  Button,
  Form,
  InputGroup,
} from "react-bootstrap";
import "./requestModal.scss";

function RequestModal({ amount, coin, address, ...props }) {
  const [copied, setCopied] = useState(false);
  const [url, setUrl] = useState();

  const inputRef = useRef(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (copied) {
        setCopied(false);
      }
    }, 2000);
    return () => clearTimeout(timeout);
  }, [copied]);

  useEffect(() => {
    setUrl(`${window.location.href}pay?amount=${amount}&chain=${coin?.chainId}&coin=${coin?.address}&receiver=${address}`);
  }, [amount, coin, address]);

  const handleCopy = useCallback(() => {
    const el = inputRef.current;
    el.select();
    el.setSelectionRange(0, 99999); // For mobile devices

    navigator.clipboard.writeText(el.value);
    setCopied(true);
  }, [setCopied, inputRef])

  return (
    <Modal {...props} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <Container className="request-modal">
          <h5> Your payment link has been generated</h5>
          <Row>
            <Col md={12} className="mt-3 mb-4">
              <div className="mb-3">
                <p className="summary-title">
                  Amount:
                  <h6 className="summary-detail">
                    {amount} {coin?.symbol}
                  </h6>
                </p>

                <p className="summary-title">Receiving address:</p>
                <h6 className="summary-detail">{address}</h6>
              </div>
              <p>Start receiving payments by sharing the link below.</p>
              <div className="d-flex mb-2 mt-3">
                <InputGroup className="shadow-none me-1">
                  <Form.Control value={url} readOnly ref={inputRef} />
                </InputGroup>
                <Button variant="primary" onClick={handleCopy}>
                  {copied ? "Copied" : "Copy"}
                </Button>
              </div>
              <a href={url} target="_blank" rel="noopener noreferrer">
                Preview link in new tab
              </a>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default RequestModal;

import AutoNumeric from 'autonumeric'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, FormControl, InputGroup, Modal } from 'react-bootstrap'
import { useDebounce } from 'react-use'
import NetworkContext from '../../context/network-context'
import networkIcon from '../../images/chain-icon'
import { fetchPairs } from '../../web3/token-utils'
import { networks } from '../../web3/web3'
import caret from '../../images/Triangle.svg'

const CoinItemSelect = ({ image, symbol, onSelect, address, chainId }) => (
  <div
    role="button"
    onKeyPress={onSelect}
    tabIndex={0}
    onClick={() => onSelect()}
    className="modal-row-item coin-item-dropdown-select align-items-center"
  >
    <div>
      <h5>
        {symbol}
        <img
          style={{ width: '15px', height: '15px', marginLeft: '0.75rem' }}
          src={networkIcon[networks[+chainId].shortName]}
          alt="icon"
        />
      </h5>
      <small className="generic-label--small-thin">
        {networks[chainId].shortName} : {address.slice(0, 5)}...
        {address.slice(30)}
      </small>
    </div>

    <img src={image} alt={symbol} width={45} height={45} />
  </div>
)

function CoinSelectInput({
  onSelect,
  targetChainId,
  defaultSelected,
  onInput,
  selected,
  value,
}) {
  const { pendingTransaction } = useContext(NetworkContext)
  const [, setSelectedValue] = useState(null)
  const [numericHandle, setNumericHandle] = useState(null)
  const [show, setShow] = useState(false)
  const [coins, setCoins] = useState(false)
  const [search, setSearch] = useState()

  const input = useRef(null)

  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  useDebounce(
    () => {
      if (!numericHandle) {
        return
      }
      numericHandle.set(value)
    },
    200,
    [value, numericHandle],
  )

  useDebounce(
    () => {
      fetchPairs(targetChainId, search, [defaultSelected.address]).then(
        setCoins,
      )
    },
    100,
    [search],
  )

  useEffect(() => {
    fetchPairs(targetChainId, null, [defaultSelected.address]).then(setCoins)
  }, [setCoins, targetChainId, defaultSelected])

  useEffect(() => {
    if (!coins || !selected) {
      return
    }
    let _selected = coins[0]
    if (defaultSelected) {
      _selected = defaultSelected
    }
    setSelectedValue(_selected)
  }, [defaultSelected, coins, selected])

  useEffect(() => {
    const handle = new AutoNumeric(input.current, 0, {
      decimalPlaces: 8,
      minimumValue: 0,
      showWarnings: false,
      allowDecimalPadding: false,
      emptyInputBehavior: 0,
      decimalCharacterAlternative: ',',
    })
    setNumericHandle(handle)
  }, [input])

  useEffect(() => {
    if (!selected) {
      return
    }
    setSelectedValue(selected)
    fetchPairs(targetChainId, null, [selected.address]).then(setCoins)
  }, [selected, targetChainId])

  const handleSelect = (coin) => {
    if (pendingTransaction) {
      return
    }
    setSelectedValue(coin)
    setShow(false)
    fetchPairs(targetChainId, null, [coin.address]).then(setCoins)
    if (onSelect) {
      onSelect(coin)
    }
  }

  return (
    <>
      <div className="'coin-select-input-wrapper'">
        {/* Couldn't display the height correctly without form-control */}
        <div className="coin-select-input form-control">
          <input
            type="text"
            autoComplete="off"
            inputMode="decimal"
            name="input"
            onChange={() => {
              onInput(numericHandle.getNumber())
            }}
            ref={input}
          />

          <div
            role="button"
            onKeyPress={handleShow}
            onClick={handleShow}
            tabIndex={0}
            className="input-symbol d-flex align-items-center"
          >
            {selected && (
              <img
                style={{
                  width: '15px',
                  height: '15px',
                  marginRight: '0.75rem',
                }}
                src={networkIcon[networks[selected?.chainId].shortName]}
                alt="icon"
              />
            )}
            <span className="me-2">{selected?.symbol}</span>
            <img
              style={{
                width: '10px',
                height: '10px',
                marginLeft: '0.5rem',
              }}
              src={caret}
              alt="more"
            />
          </div>
        </div>
      </div>

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Body style={{ height: 415 }}>
          <Col md={12} className="mb-md-5 mx-auto">
            <InputGroup className="form-hpay-input-group">
              <FormControl
                placeholder="Search here..."
                onChange={(e) => {
                  setSearch(e.target.value)
                }}
                className="form-hpay"
              />
            </InputGroup>
          </Col>
          <Col md={12} style={{ overflowX: 'scroll', maxHeight: 280 }}>
            <div>
              {coins &&
                coins.map((coin, index) => (
                  <CoinItemSelect
                    {...coin}
                    onSelect={() => handleSelect(coin)}
                    key={index}
                  />
                ))}
            </div>
          </Col>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CoinSelectInput

import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Toast } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import TransactionComplete from '../components/transaction-complete/TransactionComplete'
import TransactionForm from '../components/transaction-form/TransactionForm'
import TransactionReview from '../components/transaction-review/TransactionReview'
import './App.scss'
// import { redeemVoucher, validateVoucher } from '../services/voucher.service'

const useIsClient = () => {
  const [isClient, setClient] = useState(false);
  const key = isClient ? "client" : "server";

  useEffect(() => {
    setClient(true);
  }, []);

  return { isClient, key };
};

function App() {
  const [status, setStatus] = useState('pending')
  const [isLoading, setIsLoading] = useState(false)
  const [transactionDetails,] = useState(undefined)
  const [error, setError] = useState(null)
  const { isClient } = useIsClient();

  const { t } = useTranslation()

  const redeemHandler = async () => {
    setIsLoading(true)
    // try {
    //   // const txid ={} ;
    //   // setTransactionDetails({ ...transactionDetails, ...txid })
    setStatus('done')
    // } catch (error) {
    //   handleError(error)
    // } finally {
    //   setIsLoading(false)
    // }
  }

  const validate = async (data) => {
    console.log("*** dsa")

    // setIsLoading(true)
    // try {
    //   // const result ={};
    //   // setTransactionDetails(result)
    setStatus('review')
    // } catch (error) {
    //   handleError(error)
    // }
    // setIsLoading(false)
  }

  return (
    <Container fluid className="App-bg">
      <Container>
        <header className="App-header row d-block">
          <Row className=" p-sm-4  p-0 align-items-center flex-wrap-reverse">
            <Col md={6}>
              <div className="header-text">
                <h1>Easy, convenient and secure crypto payments</h1>
                <h6>Fast and secure gateway for crypto claming</h6>
              </div>
            </Col>
            {isClient && <Col md={6}>
              {status === 'pending' && (
                <TransactionForm
                  isLoading={isLoading}
                  handleSubmit={validate}
                />
              )}
              {status === 'review' && (
                <TransactionReview
                  isLoading={isLoading}
                  // transactionDetails={transactionDetails}
                  redeemHandler={redeemHandler}
                  onBack={() => setStatus('pending')}
                />
              )}
              {status === 'done' && (
                <TransactionComplete
                  transactionDetails={transactionDetails}
                  onBack={() => setStatus('pending')}
                />
              )}
            </Col>}
          </Row>

          {/* <div className="svg-border-angled text-white">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              fill="currentColor"
            >
              <polygon points="0,100 100,0 100,100"></polygon>
            </svg>
          </div> */}
        </header>
        <Toast
          style={{
            position: 'absolute',
            top: 0,
            right: 0,
            margin: 20,
          }}
          show={!!error}
          delay={3000}
          autohide
          onClose={() => setError(null)}
        >
          <Toast.Body>
            <svg
              className="bi bi-exclamation-circle"
              width="1em"
              height="1em"
              viewBox="0 0 16 16"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M8 15A7 7 0 108 1a7 7 0 000 14zm0 1A8 8 0 108 0a8 8 0 000 16z"
                clipRule="evenodd"
              />
              <path d="M7.002 11a1 1 0 112 0 1 1 0 01-2 0zM7.1 4.995a.905.905 0 111.8 0l-.35 3.507a.552.552 0 01-1.1 0L7.1 4.995z" />
            </svg>{' '}
            {t(error)}
          </Toast.Body>
        </Toast>
      </Container>
    </Container>
  )

  // function handleError(error) {
  //   const errorCode = error.response.status
  //   console.log(error.response)
  //   if (errorCode === 408) {
  //     setError('ERRORS.TIMEOUT_ERROR')
  //   } else {
  //     setError([`ERRORS.${error.response.data.code}`, 'ERRORS.GENERIC_ERROR'])
  //   }
  //   setStatus('pending')
  // }
}

export default App

import { useFormik } from "formik";
import React, { useCallback, useState } from "react";
import { Button, Container, Form, InputGroup, Spinner } from "react-bootstrap";
import { useEffectOnce } from "react-use";
import { fToken } from '../../util/formatNumber';
import { Tokens } from "../../web3/contracts/contracts";
import { useRelativeTokenPrice } from '../../web3/price-data';
import { getExchangeToken, getUsdCoin } from "../../web3/token-utils";
import { getDefaultRouterAddress } from '../../web3/utils';
import { useTargetNetwork } from "../../web3/web3";
import CoinSelectInput from "../coin-select-input/coin-select-input";
import RequestModal from "../request-modal/RequestModal";
import ConnectWallet from "../wallet/ConnectWallet";

import "./transctionform.scss";

function TransactionForm({ isLoading }) {

  const [amount, setAmount] = useState(0);
  const [coin, setCoin] = useState();
  const { setTargetNetwork } = useTargetNetwork();

  const [baseUsdPrice] = useRelativeTokenPrice(getUsdCoin(coin?.chainId), coin, getDefaultRouterAddress(coin?.chainId), coin?.chainId);
  const [modalShow, setModalShow] = useState(false);

  useEffectOnce(async () => {
    const _coin = await getExchangeToken(1, "USDT");
    formik.setFieldValue("coin", _coin.symbol);
    setCoin(_coin);
  });


  const updateInput = useCallback(
    ({ setFieldValue }) =>
      (value) => {
        setAmount(+value);
        setFieldValue("amount", value);
      },
    [setAmount]
  );

  const handleCoinSelect = useCallback(
    ({ setFieldValue }) =>
      (coin) => {
        setCoin(coin);
        setTargetNetwork(coin.chainId);
        updateInput(amount);
        setFieldValue("coin", coin.symbol);
        console.log("*** SElect: ", coin.symbol,);
      },
    [setCoin, setTargetNetwork, updateInput, amount],
  );

  const formik = useFormik({
    initialValues: {
      amount: 0,
      address: "",
      coin: coin,
    },
    onSubmit: (values) => {
      setModalShow(true);
    },
  });
  // validationSchema={object().shape({
  //   // amount: string().length(16, 'Invalid amount').required('Required'),
  //   address: string()
  //     .min(1)
  //     .test('isCrypto', 'Invalid Address', (value) => {
  //       return value && WAValidator.validate(value, 'BTC')
  //     })
  //     .required('Required'),
  // })}

  return (
    <>
      <Container>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group className="mb-2">
            <Form.Label>Crypto Wallet Address</Form.Label>
            <InputGroup>
              <Form.Control
                id="address"
                name="address"
                disabled={isLoading}
                isInvalid={formik.touched.address && !!formik.errors.address}
                placeholder="Address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <div className="vr mx-2" />
              <ConnectWallet />
              <Form.Control.Feedback type="invalid" className="text-right">
                {formik.errors.address}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-2">
            <Form.Label>Amount</Form.Label>
            <CoinSelectInput
              id="amount"
              defaultSelected={Tokens.BUSD}
              value={formik.values.amount}
              selected={coin}
              onInput={updateInput(formik)}
              onSelect={handleCoinSelect(formik)}
            />
            <Form.Text className="text-muted">~ {fToken(baseUsdPrice * formik.values.amount)} USD</Form.Text>
            <Form.Text className="text-muted float-end">
              Current price: 1  {coin?.symbol} = {fToken(baseUsdPrice)} USD
            </Form.Text>
            <Form.Control.Feedback type="invalid" className="text-right">
              {formik.errors.amount}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group>
            <Button variant="primary" type="submit" className="w-100 mt-3 mb-4">
              {isLoading ? (
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              ) : (
                "Request payment"
              )}
            </Button>
          </Form.Group>
        </Form>
      </Container>

      <RequestModal
        amount={formik.values.amount}
        coin={coin}
        address={formik.values.address}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </>
  );
}

export default TransactionForm;
